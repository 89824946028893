.scrollbar-track-y,
.scrollbar-thumb-y {
    width: 5px !important;
}

.scrollbar-track-x,
.scrollbar-thumb-x {
    height: 5px !important;
}

.scrollbar-thumb {
    border-radius: 0 !important;
}

.ant-popover-placement-bottom > .ant-popover-content > .ant-popover-arrow:after,
.ant-popover-placement-bottomLeft
> .ant-popover-content
> .ant-popover-arrow:after,
.ant-popover-placement-bottomRight
> .ant-popover-content
> .ant-popover-arrow:after,
.ant-popover-placement-top > .ant-popover-content > .ant-popover-arrow:after,
.ant-popover-placement-topLeft
> .ant-popover-content
> .ant-popover-arrow:after,
.ant-popover-placement-topRight
> .ant-popover-content
> .ant-popover-arrow:after {
    left: 0;
    margin-left: -4px;
}

/********** Add Your Global RTL CSS Here **********/
/* Popover */
html[dir='rtl'] .ant-popover {
    text-align: right;
}

/* Ecommerce Card */
html[dir='rtl'] .isoCardInfoForm .ant-input {
    text-align: right;
}

/* Modal */
html[dir='rtl'] .has-success.has-feedback:after,
html[dir='rtl'] .has-warning.has-feedback:after,
html[dir='rtl'] .has-error.has-feedback:after,
html[dir='rtl'] .is-validating.has-feedback:after {
    left: 0;
    right: auto;
}

html[dir='rtl'] .ant-modal-close {
    right: inherit;
    left: 0;
}

html[dir='rtl'] .ant-modal-footer {
    text-align: left;
}

html[dir='rtl'] .ant-modal-footer button + button {
    margin-left: 0;
    margin-right: 8px;
}

html[dir='rtl'] .ant-confirm-body .ant-confirm-content {
    margin-right: 42px;
}

html[dir='rtl'] .ant-btn > .anticon + span,
html[dir='rtl'] .ant-btn > span + .anticon {
    margin-right: 0.5em;
}

html[dir='rtl'] .ant-btn-loading span {
    margin-left: 0;
    margin-right: 0.5em;
}

html[dir='rtl']
.ant-btn.ant-btn-loading:not(.ant-btn-circle):not(.ant-btn-circle-outline) {
    padding-left: 25px;
    padding-right: 29px;
}

html[dir='rtl']
.ant-btn.ant-btn-loading:not(.ant-btn-circle):not(.ant-btn-circle-outline)
.anticon {
    margin-right: -14px;
    margin-left: 0;
}

/* Confirm */
html[dir='rtl'] .ant-modal.ant-confirm .ant-confirm-body > .anticon {
    margin-left: 16px;
    margin-right: 0;
    float: right;
}

html[dir='rtl'] .ant-modal.ant-confirm .ant-confirm-btns {
    float: left;
}

html[dir='rtl'] .ant-modal.ant-confirm .ant-confirm-btns button + button {
    margin-right: 10px;
    margin-left: 0;
}

/* Message */
html[dir='rtl'] .ant-message .anticon {
    margin-left: 8px;
    margin-right: 0;
}

/* Pop Confirm */
html[dir='rtl'] .ant-popover-message-title {
    padding-right: 20px;
    padding-left: 0;
}

html[dir='rtl'] .ant-popover-buttons {
    text-align: left;
}

/* Notification */
html[dir='rtl']
.ant-notification-notice-closable
.ant-notification-notice-message {
    padding-left: 24px;
    padding-right: 0;
}

html[dir='rtl']
.ant-notification-notice-with-icon
.ant-notification-notice-message,
html[dir='rtl']
.ant-notification-notice-with-icon
.ant-notification-notice-description {
    margin-right: 48px;
}

html[dir='rtl'] .ant-notification-notice-close {
    right: auto;
    left: 16px;
}

html[dir='rtl'] .ant-notification-notice-with-icon {
    left: 0;
}

/* Dropzone */
html[dir='rtl'] .dz-hidden-input {
    display: none;
}

/* Hide warning of tinyMCE */
.tox-notifications-container {
    display: none !important;
    visibility: hidden !important;
    z-index: -999999 !important;
}

/* Increase the size of reactour arrows */
#___reactour  [data-tour-elem='controls'] > button > svg  {
    transform: scale(2);
}

.antd-img-crop-modal .ant-modal-content .ant-modal-header .ant-modal-title {
    font-family: 'Rubik', sans-serif;
    font-size: 17px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.61px;
    text-align: center;
    color: #111315;
}

.antd-img-crop-modal .ant-modal-content .ant-modal-footer button span {
    font-family: 'Rubik', sans-serif;
    font-size: 14px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.5px;
    text-align: center;
    color: #939393;
}

.preview-modal {
    width: 640px!important;
}

.preview-modal .ant-modal-content{
    width: 640px;
    padding: 32px 20px;
}

.preview-modal .ant-modal-content .ant-modal-body {
    width: 100%;
    text-align: center;
}

.preview-modal .ant-modal-content .ant-modal-body p {
    color: #121415;
    font-family: 'Rubik', sans-serif;
    font-size: 14px;
    letter-spacing: 0.5px;
    line-height: 17px;
    text-align: center;
    margin: 16px 0;
}
.preview-modal .icon-filter_x {
    position: absolute;
    right: 20px;
    top: 20px;
    color: #4787FE;
    cursor: pointer;
}

.preview-modal .ant-btn {
    border-radius: 3px;
    background-color: #4787FE;
    border: 0;
    outline: 0;
    padding: 9px 18px;
    height: auto;
}
.preview-modal:hover .ant-btn {
    border-radius: 3px;
    background-color: #4787FE;
    border: 0;
    outline: 0;
    padding: 9px 18px;
    height: auto;
}

.preview-modal .ant-btn span {
    font-family: 'Rubik', sans-serif;
    font-size: 14px;
    font-weight: 500;
    line-height: 17px;
    letter-spacing: 0.5px;
    color: #ffffff;
}

.preview-modal .modal-content p.item {
    background: #F5F9FF;
    border-radius: 4px;
    font-family: 'Rubik', sans-serif;
    font-size: 14px;
    font-weight: 500;
    letter-spacing: 0.5px;
    line-height: 17px;
    text-align: center;
    color: #4787FE;
    margin-bottom: 4px;
    padding: 8px;
}

/* --- fdfd --- */
.share-with {
    width: 500px!important;
}

.share-with .main-content {
    padding: 0 20px 20px 20px ;
}

.share-with .ant-modal-content{
    width: 500px!important;
    padding: 0!important;
}

.share-with .ant-modal-content .ant-modal-body {
    width: 100%;
}

.share-with .ant-modal-content .ant-modal-body .modal-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 17px 20px 13px;
    border-bottom: 2px solid #ECF3FF;
    margin-bottom: 16px;
}

.share-with .ant-modal-content .ant-modal-body .modal-header .ant-btn:focus {
    background: #4787FE;
}

.share-with .ant-modal-content .ant-modal-body .modal-header .ant-btn {
    margin-left: 8px;
}

.share-with .ant-modal-content .ant-modal-body .modal-header .ant-btn.cancel {
    background: #E9F2FF;
}

.share-with .ant-modal-content .ant-modal-body .modal-header .ant-btn.cancel span {
    color: #4787FE;
    background: #E9F2FF;
}

.share-with .ant-modal-content .ant-modal-body .modal-header .ant-btn.cancel:focus {
    color: #4787FE;
    background: #E9F2FF;
}

.share-with .ant-modal-content .ant-modal-body .modal-subheader {
    width: 100%;
    border-radius: 5px;
    background-color: #F5F9FF;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    padding: 10px;
    margin-bottom: 16px;
}

.share-with .ant-modal-content .ant-modal-body .modal-subheader .date {
    font-family: 'Rubik', sans-serif;
    font-size: 14px;
    font-weight: 400;
    letter-spacing: 0.43px;
    line-height: 17px;
    color: #4787FE;
    margin-top: 0;
    margin-bottom: 4px;
    width: 100%;
    text-align: left;
}

.share-with .ant-modal-content .ant-modal-body .modal-subheader .report-name {
    font-family: 'Rubik', sans-serif;
    font-size: 14px;
    font-weight: 500;
    letter-spacing: 0.5px;
    line-height: 17px;
    color: #131415;
    margin-top: 0;
    margin-bottom: 0;
}

.share-with .ant-modal-content .ant-modal-body .modal-header .title {
    margin: 0;
    font-family: 'Rubik', sans-serif;
    font-size: 14px;
    font-weight: 500;
    letter-spacing: 0.5px;
    line-height: 17px;
    text-align: center;
    color: #131415;
}

.share-with .ant-modal-content .ant-modal-body .ant-checkbox, .share-with .ant-modal-content .ant-modal-body .ant-checkbox-inner, .share-with .ant-modal-content .ant-modal-body .ant-checkbox .ant-checkbox-input {
    width: 22px;
    height: 22px;
    border-radius: 50%;
    outline: 0;
}

.share-with .ant-modal-content .ant-modal-body .ant-checkbox.ant-checkbox-checked .ant-checkbox-inner::after {
     border-width: 3px;
     width: 5px;
     height: 12px;
     left: 29%;
     top: 45%;
 }

.share-with .ant-modal-content .ant-modal-body .share-list {
    padding-top: 1px;
    height: 280px;
    overflow-y: auto;
}

.share-with .ant-modal-content .ant-modal-body .share-list .list-item{
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 15px;
}
.share-with .ant-modal-content .ant-modal-body .share-list .list-item .main-info p {
    font-size: 12px;
    letter-spacing: 0.43px;
    line-height: 14px;
    color: #121415;
    text-align: left;
    font-family: 'Rubik', sans-serif;
    font-weight: 400;
    margin: 0;
}

.share-with .ant-modal-content .ant-modal-body .share-list .list-item .main-info p.sub-label {
    color: #888888;
    margin-top: 3px;
}

.share-with .ant-modal-content .ant-modal-body .share-list .list-item .ant-checkbox-wrapper {
    display: flex;
    align-items: center;
}

.share-with .ant-modal-content .ant-modal-body .share-list .list-item .role {
    font-size: 12px;
    letter-spacing: 0.43px;
    line-height: 14px;
    color: #4787FE;
    text-align: left;
    font-family: 'Rubik', sans-serif;
    font-weight: 400;
}

.share-with .ant-modal-content .ant-modal-body .ant-checkbox.ant-checkbox-checked::after {
    border: 0;
}

.share-with .ant-modal-content .ant-modal-footer {
    display: none;
}

.share-with .ant-modal-content .ant-modal-body p {
    color: #121415;
    font-family: 'Rubik', sans-serif;
    font-size: 14px;
    letter-spacing: 0.5px;
    line-height: 17px;
    text-align: center;
}
.share-with .icon-filter_x {
    position: absolute;
    right: 20px;
    top: 20px;
    color: #4787FE;
    cursor: pointer;
}

.share-with .ant-btn {
    border-radius: 3px;
    background-color: #4787FE;
    border: 0;
    outline: 0;
    padding: 9px 18px;
    height: auto;
}
.share-with:hover .ant-btn {
    border-radius: 3px;
    background-color: #4787FE;
    border: 0;
    outline: 0;
    padding: 9px 18px;
    height: auto;
}

.share-with .ant-btn span {
    font-family: 'Rubik', sans-serif;
    font-size: 14px;
    font-weight: 500;
    line-height: 17px;
    letter-spacing: 0.5px;
    color: #ffffff;
}

.share-with .modal-content p.item {
    background: #F5F9FF;
    border-radius: 4px;
    font-family: 'Rubik', sans-serif;
    font-size: 14px;
    font-weight: 500;
    letter-spacing: 0.5px;
    line-height: 17px;
    text-align: center;
    color: #4787FE;
    margin-bottom: 4px;
    padding: 8px;
}

.guide-tour-preview b {
    color: #131415;
    font-family: 'Rubik', sans-serif;
    font-size: 17px;
    font-weight: 500;
    letter-spacing: 0.61px;
    line-height: 20px;
    margin-bottom: 16px;
}

.guide-tour-preview p{
    color: #131415;
    font-family: 'Rubik', sans-serif;
    font-size: 17px;
    font-weight: normal;
    letter-spacing: 0.61px;
    line-height: 20px;
    margin-top: 16px;
}

.guide-tour-preview [data-tour-elem="controls"] {
    display: none;
}

.preview-tour {
    opacity: 0.4;
}

.ant-select-dropdown {
    margin-top: -30px!important;
    z-index: 98!important;
}

.ant-select-dropdown .ant-select-dropdown-menu-item {
    font-family: 'Rubik', sans-serif;
    font-size: 13px;
    font-weight: 500;
    letter-spacing: 0.7px;
    line-height: 18px;
    margin-bottom: 0;
    color: #121415;
    white-space: unset;
    text-overflow: unset;
}

.ant-select-dropdown-menu-item-active:not(.ant-select-dropdown-menu-item-disabled){
    background-color: transparent!important;
}

.ant-select-dropdown-menu-item:hover {
    background-color: rgba(245,249,255,1)!important;
    color: #121415;
}

.ant-select-dropdown .ant-select-dropdown-menu-item-selected:hover {
    background-color: #4787FE!important;
    color: #ffffff!important;
}

.ant-select-dropdown .ant-select-dropdown-menu-item-selected {
    background-color: #4787FE!important;
    color: #ffffff!important;
}

.antd-img-crop-modal .ant-modal-content .ant-modal-footer .ant-btn {
    height: 34px;
    margin: 0 16px 0 0;
    border-radius: 3px;
    background-color: #f2f2f2;
    border: none;
}

.antd-img-crop-modal .ant-modal-content .ant-modal-footer .ant-btn-primary {
    background-color: #4787fe;
}

.antd-img-crop-modal .ant-modal-content .ant-modal-footer .ant-btn-primary > span {
    color: white;
}

.confirm-charged .ant-modal-content .ant-modal-footer > div {
    display: flex;
    flex-direction: row-reverse;
}

.confirm-charged .ant-modal-content .ant-modal-body > p.modal-title {
    font-size: 17px;
    font-weight: 500;
    letter-spacing: 0.61px;
    line-height: 20px;
}

.confirm-charged .ant-modal-content .ant-modal-body {
    margin-bottom: 0;
    text-align: center;
}

.confirm-charged .ant-modal-content .ant-modal-body svg {
    margin-bottom: 8px;
}

.confirm-charged .ant-modal-content .ant-modal-body > p:last-child {
    margin-bottom: 0;
}

.confirm-charged .ant-modal-content .ant-modal-footer > div > button.ant-btn {
    border-radius: 3px;
    background-color: #E9F2FF;
    padding: 6px 40px;
    margin-left: 10px;
}

.confirm-charged .ant-modal-content .ant-modal-footer > div > button.ant-btn > span {
    font-size: 14px;
    font-weight: 500;
    letter-spacing: 0.5px;
    color: #4787FE;
    line-height: 17px;
    font-family: 'Rubik', sans-serif;
}

.confirm-charged .ant-modal-content .ant-modal-footer > div > button.ant-btn-primary {
    border-radius: 3px;
    background-color: #4787FE;
    padding: 6px 40px;
    margin-left: 0;
}

.confirm-charged.hide-cancel .ant-modal-content .ant-modal-footer > div > button.ant-btn:first-child {
    display: none;
}

.confirm-charged .ant-modal-content .ant-modal-footer > div > button.ant-btn-primary > span {
    font-size: 14px;
    font-weight: 500;
    letter-spacing: 0.5px;
    color: #FFFFFF;
    line-height: 17px;
    font-family: 'Rubik', sans-serif;
}

.confirm-charged .ant-modal-content .ant-modal-footer .ant-btn-primary > span {
    color: white;
}

@media (max-width: 1024px) {
    .ant-popover-inner {
        width: 300px !important;
    }
}

/********** Add Your Global Print CSS Here **********/

@page {
    margin: 16px;
    size: letter;
}

@media print {

    .main-topbar, .ant-layout-footer {
        display: none;
    }

    .isomorphicContent {
        padding-top: 0!important;
    }

    body{
        -webkit-print-color-adjust: exact !important;
    }

    .page-title {
        display: none;
    }
}


